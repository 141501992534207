import "./App.css";

import React, { useEffect } from "react";

import { ZoomMtg } from '@zoom/meetingsdk';

ZoomMtg.preLoadWasm();
ZoomMtg.prepareWebSDK();

/**
 * @function App
 * @description Main root of the app
 * @returns {JSX}
 */
const App = () => {
  var authEndpoint = 'https://g4nd5h6mlj.execute-api.ap-south-1.amazonaws.com/default/zoom-signature'
  var sdkKey = 'FCnMJUhLTmu2Uu42Qxc_8A'
  var meetingNumber = '74837418394'
  var passWord = 'AN0TrN'
  var role = 0
  var userName = 'User'
  var userEmail = 'user@cipla.com'
  var registrantToken = ''
  var zakToken = ''

  /**
   * @async
   * @function getSignature
   * @param {Event} e
   * @description generates description by hitting the hosted git repo Express server
   * @description replace localhost 4000 with the URL where the Express server runs remotely
   */
  const getSignature = async (data = null) => {
    // if (! data?.meetingNumber) {
    //   return;
    // }
    try {
      // hit the remote experss server to retrieve signature
      // meetingNumber and role are must.
      // role 0 means attendee, 1 stands for host
      const responseFromSignatureEndpoint = await fetch(authEndpoint,
        {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({
            meetingNumber:meetingNumber,
            role,
          }),
        }
      );

      // if signature is retrieved successfully, then attempt to start meeting
      const signatureJSON = await responseFromSignatureEndpoint.json();
      if (signatureJSON) {
        startMeeting(signatureJSON?.signature, data);
      }
    } catch (error) {
      alert('failed3 - ' + error);

      console.error(error);
    }

     
  }


  // leave the zoom meeting function
  const handleLeave = () => {
    const user = localStorage.getItem("userId");
    if(user){ 
      sendMsg_MeetingLeave_Success();
      localStorage.removeItem("userId");
    } 

    return "/"
   
  }

  /**
   * @function startMeeting
   * @param {String} signature
   * @description starts the zoom meeting
   */
  const startMeeting = (signature = null, data) => {
    // in the HTML document, whichever div has zmmtg-root as ID, then overwrite its style prop by display : 'block'
    document.getElementById("zmmtg-root").style.display = "block";
    if (!sdkKey || !signature) {
      alert("sdk file or signature failure")
      return;
    }
    ZoomMtg.init({
      leaveUrl: handleLeave(),
      patchJsMedia: true,
      success: (success) => {
        ZoomMtg.join({
          signature: signature,
          sdkKey: sdkKey,
          meetingNumber: meetingNumber,
          passWord:  passWord,
          userName: data?.userName || userName,
          userEmail: data?.userEmail || userEmail,
          tk: registrantToken,
          zak: zakToken,
          success: (success) => {
            ZoomMtg.getCurrentUser({
              success: function (res) {
                localStorage.setItem("userId", JSON.stringify(res.result.currentUser.userId));

        console.log(res, 'errorerrorerror')
              },
            });
          },
          error: (error) => {
            console.log(error)
            sendMsg_MeetingJoin_Error(JSON.stringify(error));
          }
        })

      },
      error: (error) => {
        console.log("============= Zoom init error", error);
      }
    })
  }

  /**
   * @function sendMsg_MeetingJoined_Connected
   * @description 1. Join success, "connected" to meeting
   */
  const sendMsg_MeetingJoined_Connected = (meetingData = null) => {
    const msg_1 = {
      type: "event",
      key: 1,
      eventProps: {
        message: "joinedZoom",
        data: JSON.stringify({
          meetingNumber: meetingData?.meetingNumber,
          meetingStatus: "connected",
          username:meetingData?.userName
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_1));
    } else { 
        window.postMessage(JSON.stringify(msg_1))
    }

  };

  /**
   * @function sendMsg_MeetingJoined_Connecting
   * @description 2. Join success, "connecting" to meeting
   */
  const sendMsg_MeetingJoined_Connecting = (meetingData = null) => {
    const msg_2 = {
      type: "event",
      key: 2,
      eventProps: {
        message: "joinedZoom",
        data: JSON.stringify({
          meetingNumber: meetingData?.meetingNumber,
          meetingStatus: "connecting",
          username:meetingData?.userName
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_2));
    } else { 
        window.postMessage(JSON.stringify(msg_2))
    }
  };

  /**
   * @function sendMsg_MeetingJoined_Disconnected
   * @description 3. Join success, "disconnected" to meeting
   */
  const sendMsg_MeetingJoined_Disconnected = (meetingData = null) => {
    const msg_3 = {
      type: "event",
      key: 3,
      eventProps: {
        message: "joinedZoom",
        data: JSON.stringify({
          meetingNumber: meetingData?.meetingNumber,
          meetingStatus: "disconnected",
          username:meetingData?.userName
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_3));
    } else { 
        window.postMessage(JSON.stringify(msg_3))
    }
  };

  /**
   * @function sendMsg_MeetingJoined_Reconnecting
   * @description 4. Join success, "reconnecting" to meeting
   */
  const sendMsg_MeetingJoined_Reconnecting = (meetingData = null) => {
    const msg_4 = {
      type: "event",
      key: 4,
      eventProps: {
        message: "joinedZoom",
        data: JSON.stringify({
          meetingNumber: meetingData?.meetingNumber,
          meetingStatus: "reconnecting",
          username:meetingData?.userName
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_4));
    } else { 
        window.postMessage(JSON.stringify(msg_4))
    }
  };

  /**
   * @function sendMsg_MeetingJoined_Waiting
   * @description  5. Join success, "waiting" in meeting room
   */
  const sendMsg_MeetingJoined_Waiting = (meetingData = null) => {
    const msg_5 = {
      type: "event",
      key: 5,
      eventProps: {
        message: "joinedZoom",
        data: JSON.stringify({
          meetingNumber: meetingData?.meetingNumber,
          meetingStatus: "waiting",
          username:meetingData?.userName
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_5));
    } else { 
        window.postMessage(JSON.stringify(msg_5))
    }
  };

  /**
   * @function sendMsg_MeetingJoin_Error
   * @argument {String} errorMessage
   * @description  6. join error, "error" in joining zoom meeting
   * @description pass errorMessage from onError call back of ZoomMtg.join()
   */
  const sendMsg_MeetingJoin_Error = (errorMessage = "") => {
    const msg_6 = {
      type: "event",
      key: 6,
      eventProps: {
        message: "errorZoom",
        data: JSON.stringify({
          errorMessage,
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_6));
    } else { 
        window.postMessage(JSON.stringify(msg_6))
    }
  };

  /**
   * @function sendMsg_MeetingLeave_Success
   * @description  7. leave success, meeting ended
   */
  const sendMsg_MeetingLeave_Success = () => {
    const msg_7 = {
      type: "event",
      key: 7,
      eventProps: {
        message: "leaveZoom",
        data: JSON.stringify({
          endedBy: "user",
        }),
      },
    };
    if (window?.ReactNativeWebView) {
        window?.ReactNativeWebView?.postMessage(JSON.stringify(msg_7));
    } else { 
        window.postMessage(JSON.stringify(msg_7))
    }
  };

  /**
   * @function useEffect
   * @description to handle the listener to receive msg from React native
   * @description PWA developers can receive this e.data and utilize it wherever required
   */
  let meetingData = null; 
  
  useEffect(() => {
    // if (document.addEventListener) {
    //     document.addEventListener("message", listenMessage, false);
    // } 
    // else {
    //     document.attachEvent("onmessage", listenMessage);
    // }
    if (window.addEventListener) {
        window.addEventListener("message", listenMessage, false);
    } else {
        window.attachEvent("onmessage", listenMessage);
    }

    // function listenMessage(msg){
    //   if (JSON.parse(msg.data).type && JSON.parse(msg.data).type === "LEAVE_MEETING") {
    //     let text = 'Are you sure, want to Leave meeting?';
    //     if (window.confirm(text) == true) {
    //       sendMsg_MeetingLeave_Success();
    //     } 
        
    //   }
    //   // let mesaagedata = JSON.parse(msg.data)
    //   if(JSON.parse(msg.data).meetingNumber){
    //     // alert(JSON.stringify(msg))
    //     getSignature(JSON.parse(msg.data));
    //     meetingData = msg.data;
    //   }
      
    // }
    function listenMessage(msg) {
      let parsedData;
      
      // First, try to parse the data if it's a string
      if (typeof msg.data === 'string') {
        try {
          parsedData = JSON.parse(msg.data);
        } catch (error) {
          console.error("Error parsing message data:", error);
          return;
        }
      } else if (typeof msg.data === 'object') {
        // If it's already an object, use it directly
        parsedData = msg.data;
      } else {
        console.error("Unexpected message data type:", typeof msg.data);
        return;
      }
    
      // Now we can safely use parsedData
      if (parsedData.type && parsedData.type === "LEAVE_MEETING") {
        let text = 'Are you sure you want to leave the meeting?';
        if (window.confirm(text)) {
          sendMsg_MeetingLeave_Success();
        }
      }
    
      if (parsedData.meetingNumber) {
        getSignature(parsedData);
        meetingData = parsedData;
      }
    }
    ZoomMtg.inMeetingServiceListener("onMeetingStatus", (data) => {
      // {status: 1(connecting), 2(connected), 3(disconnected), 4(reconnecting)}
      const status = data?.meetingStatus || null;
      if (status) {
        switch (status) {
          case 1:
            sendMsg_MeetingJoined_Connecting(meetingData);
            break;
          case 2:
            sendMsg_MeetingJoined_Connected(meetingData);
            break;
          case 3:
            sendMsg_MeetingJoined_Disconnected(meetingData);
            break;
          case 4:
            sendMsg_MeetingJoined_Reconnecting(meetingData);
            break;
          default:
            break;
        }
      }
    });

    ZoomMtg.inMeetingServiceListener("onUserIsInWaitingRoom", () => {
      sendMsg_MeetingJoined_Waiting(meetingData);
    });
  }, []);

  return <div className="App">
    <main>
        <h1>Zoom Meeting SDK Sample React</h1>

        <button onClick={getSignature}>Join Meeting</button>
      </main>
  </div>;
};

export default App;